/* eslint-disable class-methods-use-this */

import BaseCollection from './_Base'
import Attribute from '../models/Attribute'
import { fetchAttributesRequest } from '@/request/globalApi/requests/attributeRequests'
import { omit } from 'lodash'

/**
 * AttributesCollection
 * @linkhttps://vuemc.io/#collection-instances
 */
class AttributesCollection extends BaseCollection {
  options() {
    return {
      model: Attribute,
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  /**
   * 
   */
  async fetch(params) {
    const { data } = await fetchAttributesRequest(params)
    this.set({ ...omit(data, 'attributes'), items: data.attributes })
  }
}

export default AttributesCollection
