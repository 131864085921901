/* eslint-disable class-methods-use-this */

import BaseModel from './_Base'

/**
 * Attribute
 * @link https://vuemc.io/#basic-usage
 */
class Attribute extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      name: {
        fr: '',
        en: '',
      },
      filterable: false,
      required: false,
      type: 'choice',
      categories: [],
      values: [],
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
      name: Object,
      filterable: Boolean,
      required: Boolean,
      type: String,
      categories: categories => categories || [],
      values: values => values || [],
    }
  }

  // ? NOTE: Which validation library to choose?
  // Attribute validation, acts after mutations

  // TODO
  // validation() {
  //   return {

  //   }
  // }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------
}

export default Attribute
